import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'OncoSexome: A Multifaceted Knowledge Base of the Sex-based Difference in Oncological Drug Response, Molecular Biomarker, Risk Factor, and Host Microbiome'
    }
  },

  {
    path: '/',
    name: 'to-be-available',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToBeAvailable.vue'),
    meta: {
      title: 'To be available | OncoSexome'
    }
  },



  {
    path: '/data/drug',
    name: 'data-drug',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataDrug.vue'),
    meta: {
      title: 'Sex-based Differences in the Antineoplastic Drugs and Response (SDR) Information | OncoSexome'
    }
  },
  {
    path: '/data/biomarker',
    name: 'data-molecule',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataMolecule.vue'),
    meta: {
      title: 'Sex-based Differences in the Oncology Relevant Biomarker (SBM) Information | OncoSexome'
    }
  },
  {
    path: '/data/microbiota',
    name: 'data-microbiota',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataMicrobiota.vue'),
    meta: {
      title: 'Sex-based Differences in the Microbial Landscape in Cancer Host (SML) Information | OncoSexome'
    }
  },
  {
    path: '/data/factor',
    name: 'data-factor',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataRiskFactor.vue'),
    meta: {
      title: 'Sex-based Differences in the Risk Factors for Cancer (SRF) Information | OncoSexome'
    }
  },
  {
    path: '/data/disease',
    name: 'data-disease',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataDisease.vue'),
    meta: {
      title: 'Cancer Information | OncoSexome'
    }
  },




  {
    path: '/search-drug-page',
    name: 'search-drug-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDrugPage.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Antineoplastic Drugs and Response (SDR) | OncoSexome'
    }
  },
  
  {
    path: '/search-biomarker-page',
    name: 'search-biomarker-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchBiomarkerPage.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Oncology Relevant Biomarker (SBM) | OncoSexome'
    }
  },
  
  {
    path: '/search-factor-page',
    name: 'search-factor-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchFactorPage.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Risk Factors for Cancer (SRF) | OncoSexome'
    }
  },
  {
    path: '/search-microbiota-page',
    name: 'search-microbiota-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchMicrobiotaPage.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Microbial Landscape in Cancer Host (SML) | OncoSexome'
    }
  },

  {
    path: '/search-disease-page',
    name: 'search-disease-page',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchDiseasePage.vue'),
    meta: {
      title: 'Search for Cancer Type | OncoSexome'
    }
  },




  {
    path: '/search-drug',
    name: 'search-drug',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchResultDrug.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Antineoplastic Drugs and Response (SDR) | OncoSexome'
    }
  },
  {
    path: '/search-disease',
    name: 'search-disease',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchResultDisease.vue'),
    meta: {
      title: 'Search for Disease | OncoSexome'
    }
  },
  {
    path: '/search-biomarker',
    name: 'search-biomarker',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchResultBiomarker.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Oncology Relevant Biomarker (SBM) | OncoSexome'
    }
  },
  {
    path: '/search-factor',
    name: 'search-factor',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchResultFactor.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Risk Factors for Cancer (SRF) | OncoSexome'
    }
  },
  {
    path: '/search-microbiota',
    name: 'search-microbiota',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchResultMicrobiota.vue'),
    meta: {
      title: 'Search for Sex-based Differences in the Microbial Landscape in Cancer Host (SML) | OncoSexome'
    }
  },



  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "about" */ '../views/DownloadPage.vue'),
    meta: {
      title: 'Full Data Download | OncoSexome'
    }
  },



  // {
  //   path: '/browse',
  //   name: 'browse',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/BrowsePage.vue'),
  //   meta: {
  //     title: 'Browse Biomarkers | OncoSexome'
  //   }
  // },



  {
    path: '/manual',
    name: 'manual',
    component: () => import(/* webpackChunkName: "about" */ '../views/ManualPage.vue'),
    meta: {
      title: 'Manual | OncoSexome'
    }
  },

  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue'),
    meta: {
      title: 'Contact US | OncoSexome'
    }
  },




  {
    path: '/update-data-SR',
    name: 'update-data-SR',
    component: () => import(/* webpackChunkName: "about" */ '../views/UpdateDate.vue'),
    meta: {
      title: 'Update Data | OncoSexome'
    }
  },








  {
    path: '/to-be-available',
    name: 'to-be-available',
    component: () => import(/* webpackChunkName: "about" */ '../views/ToBeAvailable.vue'),
    meta: {
      title: 'To be available | OncoSexome'
    }
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
