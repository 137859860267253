import { render, staticRenderFns } from "./SearchBiomarkerBlock.vue?vue&type=template&id=f7d85496&scoped=true&"
import script from "./SearchBiomarkerBlock.vue?vue&type=script&lang=js&"
export * from "./SearchBiomarkerBlock.vue?vue&type=script&lang=js&"
import style0 from "./SearchBiomarkerBlock.vue?vue&type=style&index=0&id=f7d85496&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7d85496",
  null
  
)

export default component.exports