<template>
  <div class="main-width">
    <div :class="['bioClass-part',`${activeName}-home`]" style="margin-top: -5px; padding-top: 40px">
      <div
        style="
          display: flex;
          margin: auto;
          margin-bottom: 30px;
          width: 96%;
          border-radius: 10px;
          border: 3px solid #c8745e;
        "
        :class="[
          'bc-div',
          'bc5-div',
          activeName == 'Disease' || activeName == 'About'
            ? ''
            : 'deactivation',
        ]"
        @click="activeName = 'Disease'"
      >
        <div
          class="bc-logo"
          style="
            height: 100px;
            width: 35%;
            justify-content: space-around;
            border: 0px;
            border-radius: 10px;
          "
        >
          <i class="icon iconfont icon-cancer1 search-page-icon2"></i>
          <i class="icon iconfont icon-ICD-neoplasms search-page-icon2"></i>
          <i class="icon iconfont icon-cancer2 search-page-icon2"></i>
        </div>

        <div
          class="bc-name"
          style="height: 100px; width: 30%; border-radius: unset"
        >
          <div >Search for Cancer Type<br> with Sex-based Differences</div>
        </div>
        <div
          class="bc-logo"
          style="
            height: 100px;
            width: 35%;
            justify-content: space-around;
            border: 0px;
            border-radius: 10px;
          "
        >
          <i class="icon iconfont icon-cancer2 search-page-icon2"></i>
          <i class="icon iconfont icon-ICD-neoplasms search-page-icon2"></i>
          <i class="icon iconfont icon-cancer1 search-page-icon2"></i>
        </div>
      </div>
      <div class="bioClass-row" style="margin-bottom: 0px;">
        <div class="">
          <div
            :class="[
              'bc-div',
              'bc3-div',
              activeName == 'Drug' || activeName == 'About'
                ? ''
                : 'deactivation',
            ]"
            @click="activeName = 'Drug'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-drug1 search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>
                Sex-based Differences<br />in Antineoplastic Drugs<br /> and Response
                
                (SDR)
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div
            :class="[
              'bc-div',
              'bc2-div',
              activeName == 'Biomarker' || activeName == 'About'
                ? ''
                : 'deactivation',
            ]"
            @click="activeName = 'Biomarker'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-biomarker search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>
                Sex-based Differences<br />in Oncology Relevant<br />Biomarker
                (SBM)
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <div
            :class="[
              'bc-div',
              'bc4-div',
              activeName == 'Factor' || activeName == 'About'
                ? ''
                : 'deactivation',
            ]"
            @click="activeName = 'Factor'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-molecule2 search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>
               Sex-based Differences<br />in Risk Factors<br />for Cancer
                (SRF)
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div
            :class="[
              'bc-div',
              'bc1-div',
              activeName == 'Microbiota' || activeName == 'About'
                ? ''
                : 'deactivation',
            ]"
            @click="activeName = 'Microbiota'"
          >
            <div class="bc-logo">
              <div style="margin: auto">
                <i class="icon iconfont icon-Microbiota1 search-page-icon"></i>
              </div>
            </div>
            <div class="bc-name">
              <div>
                Sex-based Differences<br />in Microbial Landscape<br />in Cancer Host
                (SML)
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <p style="margin-top: 20px">
        OncoSexome offers a comprehensive selection of 5 search blocks. To
        access specific information, simply click on the corresponding icon
        above to choose the desired block within OncoSexome.
      </p>
      <p v-show="activeName == 'Drug'">
        The current search scope of OncoSexome is:
        <span span style="color: #5287e3; font-weight: bold; font-style: italic"
          >Sex-based Differences in the Antineoplastic Drugs and Response (SDR)</span
        >
      </p>
      <p v-show="activeName == 'Biomarker'">
        The current search scope of OncoSexome is:
        <span span style="color: #749a5b; font-weight: bold; font-style: italic"
          >based Differences in the Oncology Relevant Biomarker (SBM)</span
        >
      </p>
      <p v-show="activeName == 'Factor'">
        The current search scope of OncoSexome is:
        <span span style="color: #8c59b3; font-weight: bold; font-style: italic"
          >Sex-based Differences in the Risk Factors for Cancer (SRF)</span
        >
      </p>
      <p v-show="activeName == 'Microbiota'">
        The current search scope of OncoSexome is:
        <span span style="color: #b69258; font-weight: bold; font-style: italic"
          >Sex-based Differences in the Microbial Landscape in Cancer Host (SML)</span
        >
      </p>
      <p v-show="activeName == 'Disease'">
        The current search scope of OncoSexome is:
        <span span style="color: #c8745e; font-weight: bold; font-style: italic"
          >Sex-differential Cancer</span
        >
      </p> -->
    </div>

    <div v-show="activeName == 'Drug'">
      <SearchDrugBlock></SearchDrugBlock>
    </div>

    <div v-show="activeName == 'Biomarker'">
      <SearchBiomarkerBlock></SearchBiomarkerBlock>
    </div>

    <div v-show="activeName == 'Factor'">
      <SearchFactorBlock></SearchFactorBlock>
    </div>
    <div v-show="activeName == 'Microbiota'">
      <SearchMicrobiotaBlock></SearchMicrobiotaBlock>
    </div>
    <div v-show="activeName == 'Disease'">
      <SearchDiseaseBlock></SearchDiseaseBlock>
    </div>
    <div v-show="activeName == 'About'">
      <div class="bioClass-part">

        <!-- <p style="margin-bottom: 20px">
          <img
            src="/pic/OncoSexome-about.png"
            style="width: 100%; margin-bottom: -12px; margin-right: 10px"
          />
        </p> -->
        <div
          class="v-center"
          style="
            height: 899px;
            width: 1190px;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            background: url(/pic/OncoSexome-about.png);
            background-size: contain;
            position: relative;
          "
        >
          <div
            style="
              position: absolute;
              cursor: pointer;
              top: 30px;
              height: 35px;
              width: 272px;
              right: 214px;
              text-align: right;
            "
            @click="playAudio"
            title="Click to Read OncoSexome"
          >
            <img
              src="/pic/play.png"
              style="display: initial; width: 35px; margin-top: 4px"
            />
            <audio ref="audio" src="/pic/OncoSexome.mp3" preload="auto"></audio>
          </div>
        </div>
        <!-- <p style="margin-bottom: 20px">
          <img
            :src="`/pic/biomarkerPNG.png`"
            style="width: 40px; margin-bottom: -12px; margin-right: 10px"
          />
          <span style="color: #749a5b; font-weight: bold; font-style: italic"
            >SBM</span
          >&nbsp; includes four subdomains on sex differences in Omics Variation
          (OV), Endocrine Regulation (ER), Pharmacogenomics (PG), and the Immune
          Response (IR), which integrally describe the molecular and cellular
          profiles different in, or specific to males and females. OV includes
          sex-biased genetic variants and expression profiles. OV will report
          both cancer-specific results and data from healthy individuals as sex
          differences are intrinsic to the human genome at baseline. ER mainly
          refers to sex-steroid hormones and their receptors with divergent
          influences on cancer risks/progression. PG displays genes and
          metabolites associated with sex-differential responses to anti-cancer
          drugs. IS mainly includes immune cells with sex-biased abundance. The
          SBM category is estimated to include ~ 4600 genes, 20 cellular types,
          and ~ 40 hormonal markers.
        </p>

        <p style="margin-bottom: 20px">
          <img
            :src="`/pic/drugPNG.png`"
            style="width: 40px; margin-bottom: -12px; margin-right: 10px"
          />
          <span style="color: #5287e3; font-weight: bold; font-style: italic"
            >STA</span
          >&nbsp; utilizes three descriptors to depict sex differences in
          anti-cancer therapy: Efficacy/Effectiveness (EF), Adverse Response
          (AR), and Pharmacokinetics (PK). This topic will include information
          on 1000+ drugs (mostly anti-neoplastic drugs). EF is determined by
          post-treatment endpoints (e.g., overall survival and response rates);
          AR includes various drug-induced toxicity or adverse events using
          real-world/literature evidence; and PK is reflected by pharmacological
          parameters such as clearance and excretion.
        </p>

        <p style="margin-bottom: 20px">
          <img
            :src="`/pic/factorPNG.png`"
            style="width: 40px; margin-bottom: -12px; margin-right: 10px"
          />
          <span style="color: #8c59b3; font-weight: bold; font-style: italic"
            >SRF</span
          >&nbsp; is a novel part of our database that has never been mentioned
          elsewhere in other databases. Consideration of cancer risk factors is
          crucial as cancer is a type of environmentally mediated disease[32].
          We innovatively adopt the idea of the exposome and collect data on sex
          differences in responses to cancer-causing risk factors that have been
          mainly cataloged by The International Agency for Research on Cancer
          (IARC) as carcinogens. These responses mainly include sex-differential
          carcinogenic responses, cancer risks, toxicity, and gene-environmental
          interactions. We estimate there will be ~100 such SRFs for over 40
          cancer types.
        </p>

        <p style="margin-bottom: 20px">
          <img
            :src="`/pic/microbiotaPNG.png`"
            style="width: 40px; margin-bottom: -12px; margin-right: 10px"
          />
          <span style="color: #b69258; font-weight: bold; font-style: italic"
            >SML</span
          >&nbsp; is also unprecedented. We have collected data on over 1000
          microbes identified in patients’ specimens or samples from laboratory
          animal models (gut, tumor tissues, feces, etc.) for at least 13 cancer
          types. SML data is collected based on sex-biased microbial
          compositions measured by abundance or diversity.
        </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import SearchMicrobiotaBlock from "@/components/searchBlock/SearchMicrobiotaBlock.vue";
import SearchDiseaseBlock from "@/components/searchBlock/SearchDiseaseBlock.vue";
import SearchFactorBlock from "@/components/searchBlock/SearchFactorBlock.vue";
import SearchBiomarkerBlock from "@/components/searchBlock/SearchBiomarkerBlock.vue";
import SearchDrugBlock from "@/components/searchBlock/SearchDrugBlock.vue";
import pathUrl from "@/api/path";
export default {
  name: "HomeView",
  data() {
    return {
      activeName: "About",
      localUrl: pathUrl.localUrl,

      select_radio: "1,2,3,4,5",
    };
  },
  components: {
    SearchMicrobiotaBlock,
    SearchDiseaseBlock,
    SearchFactorBlock,
    SearchBiomarkerBlock,
    SearchDrugBlock,
  },

  methods: {
    playAudio() {
      this.$refs.audio.play();
    },
  },
  beforeMount() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
@select-disease-color: #f1f1f1;

.tag-icon {
  transition: All 0.4s ease-in-out;
  -webkit-transition: All 0.4s ease-in-out;
  -moz-transition: All 0.4s ease-in-out;
  -o-transition: All 0.4s ease-in-out;
}

.tag-normal:hover {
  .tag-icon {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

.tag-normal {
  display: flex;
  height: 60px;

  cursor: pointer;
  border-radius: 30px;
  .tag-icon {
    margin: auto 0;
    width: 50px;
    height: 50px;
    background: #fff;
    border-radius: 25px;
    margin-left: 6px;
    display: flex;
  }
  .tag-icon-hover {
    margin-right: 18px;
    display: flex;
    .fa {
      font-size: 27px;
      margin: auto;
    }
  }
  .tag-title {
    margin: auto;
    font-size: 24px;
    padding-left: 18px;
    padding-right: 24px;
    width: 120px;
    text-align: center;
    font-weight: bold;
    // font-style: italic;
  }
  .icon {
    margin: auto;
    font-size: 27px;
  }
}
.tag-biomarker {
  background: #ee7700;
  border: 2px solid #ee7700;
  color: #ee7700;
  .icon {
    color: #ee7700;
  }
}
.tag-drug {
  background: #5287e3;
  border: 2px solid #5287e3;
  color: #5287e3;
  // border: 2px solid #5287e3;
  //   background: #5287e33b;
  .icon {
    color: #5287e3;
  }
}

.tag-disease {
  background: #d36157;
  border: 2px solid #d36157;
  color: #d36157;
  .icon {
    color: #d36157;
  }
}
.deactive {
  background: #eeeeee;
}
.active {
  color: #fff;
}
.new-font {
  font-family: "ZabriskieInternational Light Regular";

  text-align: center;
  font-size: 40px;
  margin: -15px 0 -15px 0;
  color: #ee7700;
  font-weight: bold;
}

.home-interpretation {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  box-shadow: 4px 4px 4px 5px #e4e8f5;
}
/deep/.el-tabs__header {
  top: 271px;
}

/deep/.el-tabs__nav-wrap::after {
  background-color: transparent;
}
</style>
