<template>
  <div>
    <div-unit>
      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p
            style="font-weight: bold; margin-left: 92px; font-size: 22px"
            class="search-color"
          >
            Search for <i>Cancer Type Entries</i> in Whole Database:
          </p>
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-input
            v-model="fullText"
            placeholder="Enter the keyword"
            style="width: 900px; font-size: 20px"
          >
          </el-input>
          <el-button
            class="btn-submit"
            @click="submitFullText()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
        <p style="margin-left: 92px; font-size: 18px">
          Please search for a specific cancer by <b><i>name</i></b> or <b><i>related factors</i></b> (e.g., drugs, molecules, microbes, risk factors).   
        </p>
        <p style="margin-left: 92px; font-size: 18px">
          Example:
          <a
            href="/search-disease?api=fullText&keyword=Colorectal cancer"
            hreflang="en"
            class="search-color"
            >Colorectal cancer</a
          >;
          <a
            href="/search-disease?api=fullText&keyword=Lung cancer"
            hreflang="en"
            class="search-color"
            >Lung cancer</a
          >;
          <a
            href="/search-disease?api=fullText&keyword=Fluorouracil"
            hreflang="en"
            class="search-color"
            >Fluorouracil</a
          >;
          <a
            href="/search-disease?api=fullText&keyword=SHBG"
            hreflang="en"
            class="search-color"
            >SHBG</a
          >;
          <a
            href="/search-disease?api=fullText&keyword=Alcoholic beverages"
            hreflang="en"
            class="search-color"
            >Alcoholic beverages</a
          >;
          <a
            href="/search-disease?api=fullText&keyword=Burkholderiales"
            hreflang="en"
            class="search-color"
            >Burkholderiales</a
          >
          ...
        </p>
      </div>
      <div style="display: flex; flex-direction: column; margin-bottom: 40px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for <i>Cancer Type Entries</i> by Disease Class:
          </p>
        </div>

        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="disease_name"
            filterable
            default-first-option
            placeholder="Please select a disease class"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in disease_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDiseaseList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for <i>Cancer Type Entries</i> by Therapeutic Class & Therapeutic Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the therapeutic class first, then a list of
            therapeutic name under the selected class will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="drug_calss"
            filterable
            default-first-option
            placeholder="Step1: Please select a therapeutic class"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_calss_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetDrugClassList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="drug_calss_name_options_check"
            v-model="drug_calss_name"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a therapeutic name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in drug_calss_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitDrugClassList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for <i>Cancer Type Entries</i> by Biomarker Group & Biomarker Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the biomarker group first, then a list of biomarker
            name under the selected class will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="biomarker_group"
            filterable
            default-first-option
            placeholder="Step1: Please select a biomarker group"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_group_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetBiomarkerGroupList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>

        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="biomarker_group_name_options_check"
            v-model="biomarker_group_name"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in biomarker_group_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitBiomarkerGroupList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for <i>Cancer Type Entries</i> by IARC Group & Risk Factor Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the IARC group first, then a list of risk factor name
            under the selected class will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="factor_group"
            filterable
            default-first-option
            placeholder="Step1: Please select an IARC group"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in factor_group_options"
              :key="index"
              :label="`IARC Group: ${item.name}`"
              :value="item.name"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetFactorGroupList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="factor_group_name_options_check"
            v-model="factor_group_name"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a risk factor name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in factor_group_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitFactorGroupList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>

      <div style="display: flex; flex-direction: column; margin-bottom: 50px">
        <div>
          <p style="font-weight: bold; margin-left: 92px; font-size: 22px">
            Search for <i>Cancer Type Entries</i> by Microbiota Order & Microbiota Name:
          </p>
        </div>
        <div>
          <p style="margin-left: 92px; font-size: 18px">
            <i class="icon iconfont icon--idea" aria-hidden="true"></i> Tips:
            Please select the microbiota order first, then a list of biomarker
            name under the selected class will be available.
          </p>
        </div>
        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="options1_check"
            v-model="microbiota_order"
            filterable
            default-first-option
            placeholder="Step1: Please select a microbiota order"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in microbiota_order_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>

          <el-button
            class="btn-submit"
            @click="resetMicrobiotaOrderList()"
            style="font-size: 22px; width: 120px"
            >Reset</el-button
          >
        </div>
        <div style="height: 10px"></div>

        <div style="display: flex; margin: 0 auto">
          <el-select
            v-loading="microbiota_order_name_options_check"
            v-model="microbiota_order_name"
            filterable
            default-first-option
            ref="searchSelect"
            placeholder="Step2: Please select a biomarker name"
            style="width: 900px"
          >
            <el-option
              v-for="(item, index) in microbiota_order_name_options"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <el-button
            class="btn-submit"
            @click="submitMicrobiotaOrderList()"
            style="font-size: 22px; width: 120px"
            >Search</el-button
          >
        </div>
      </div>
    </div-unit>
  </div>
</template>
  
  <script>
import DivUnit from "@/components/DivUnit.vue";
import api from "@/api/index";
import pathUrl from "@/api/path";
export default {
  // name: "AdvancedAnalysis",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      fullText: "",

      disease_name_options: [],
      drug_calss_options: [],
      drug_calss_name_options: [],
      biomarker_group_options: [],
      biomarker_group_name_options: [],
      factor_group_options: [],
      factor_group_name_options: [],
      microbiota_order_options: [],
      microbiota_order_name_options: [],

      disease_name: "",
      drug_calss: "",
      drug_calss_name: "",
      biomarker_group: "",
      biomarker_group_name: "",
      factor_group: "",
      factor_group_name: "",
      microbiota_order: "",
      microbiota_order_name: "",

      options1_check: true,
      drug_calss_name_options_check: false,
      biomarker_group_name_options_check: false,
      factor_group_name_options_check: false,
      microbiota_order_name_options_check: false,
    };
  },
  components: {
    DivUnit,
  },

  methods: {
    submitFullText() {
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "fullText",
          keyword: this.fullText,
        },
      });
    },

    submitDiseaseList() {
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byDisease",
          keyword: this.disease_name,
        },
      });
    },

    resetDrugClassList() {
      this.drug_calss = "";
      this.drug_calss_name = "";
    },
    submitDrugClassList() {
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byDrugName",
          group: this.drug_calss,
          keyword: this.drug_calss_name,
        },
      });
    },

    resetBiomarkerGroupList() {
      this.biomarker_group = "";
      this.biomarker_group_name = "";
    },
    submitBiomarkerGroupList() {
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byBiomarkerName",
          keyword: this.biomarker_group_name,
        },
      });
    },

    resetFactorGroupList() {
      this.factor_group = "";
      this.factor_group_name = "";
    },
    submitFactorGroupList() {
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byFactorName",
          keyword: this.factor_group_name,
        },
      });
    },

    resetMicrobiotaOrderList() {
      this.microbiota_order = "";
      this.microbiota_order_name = "";
    },
    submitMicrobiotaOrderList() {
      this.$router.push({
        path: `/search-disease`,
        query: {
          api: "byMicrobiotaName",
          keyword: this.microbiota_order_name,
        },
      });
    },
  },

  beforeMount() {},
  mounted() {
    api.getSearchDiseaseList().then((res) => {
      this.options1_check = false;
      this.disease_name_options = res.data.disease_name_options;
      this.drug_calss_options = res.data.drug_calss_options;
      this.biomarker_group_options = res.data.biomarker_group_options;
      this.factor_group_options = res.data.factor_group_options;
      this.microbiota_order_options = res.data.microbiota_order_options;
    });
  },

  watch: {
    drug_calss: {
      handler: function () {
        this.drug_calss_name_options_check = true;
        this.drug_calss_name = "";
        api
          .postDiseaseSeconedDropdownList({
            type:'drug',
            data: this.drug_calss,

          })
          .then((res) => {
            this.drug_calss_name_options_check = false;
            this.drug_calss_name_options = res.data.drug_calss_name_options;
          });
      },
      deep: true,
    },
    biomarker_group: {
      handler: function () {
        this.biomarker_group_name_options_check = true;
        this.biomarker_group_name = "";
        api
        .postDiseaseSeconedDropdownList({
            type:'biomarker',
            data: this.biomarker_group,
          })
          .then((res) => {
            this.biomarker_group_name_options_check = false;
            this.biomarker_group_name_options =
              res.data.biomarker_group_name_options;
          });
      },
      deep: true,
    },

    factor_group: {
      handler: function () {
        this.factor_group_name_options_check = true;
        this.factor_group_name = "";
        api
        .postDiseaseSeconedDropdownList({
            type:'factor',
            data: this.factor_group,
          })
          .then((res) => {
            this.factor_group_name_options_check = false;
            this.factor_group_name_options = res.data.factor_group_name_options;
          });
      },
      deep: true,
    },
    microbiota_order: {
      handler: function () {
        this.microbiota_order_name_options_check = true;
        this.microbiota_order_name = "";
        api
        .postDiseaseSeconedDropdownList({
            type:'microbiota',
            data: this.microbiota_order,
          })
          .then((res) => {
            this.microbiota_order_name_options_check = false;
            this.microbiota_order_name_options =
              res.data.microbiota_order_name_options;
          });
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
@color-theme: #c8745e;
@bg-theme: #fcf7f6;
@color-theme-hover: #c8745e94;

.page-title,
.search-color {
  color: @color-theme;
}

/deep/.el-button:hover,
/deep/.el-button:focus {
  color: #ffffff;
  background: @color-theme-hover;
  border-color: @color-theme-hover;
  outline: 0;
}

/deep/input {
  height: 50px;
  padding-left: 15px;
  font-size: 20px;
  border: 2px solid @color-theme;
  // border-bottom-left-radius: 10px;
  // border-top-left-radius: 10px;
  // border-bottom-right-radius: 0px;
  // border-top-right-radius: 0px;
  border-radius: 0;
  outline: none;
}
/deep/.el-select:hover,
/deep/.el-range-editor.is-active,
/deep/.el-range-editor.is-active:hover,
/deep/.el-input__inner {
  border-color: @color-theme !important;
}
/deep/.el-button {
  font-size: 22px !important;
  height: 50px;
  border-radius: 0;
  border: 2px;
  background-color: @color-theme;
}
/deep/.div-title {
  display: none;
}
/deep/.div-unit {
  border-color: @color-theme;
}
/deep/.div-content {
  border-top: 0;
  background-color: @bg-theme;
}
</style>
  