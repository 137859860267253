<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div id="app">
    <el-container style="min-height: 100vh; min-width: 1280px">
      <el-backtop></el-backtop>

      <el-header style="height: auto" class="home-gradient">
        <div style="background-color: #acdfd7; height: 225px">
          <div
            class="content-width"
            style="width: 1250px; display: flex; justify-content: space-between"
          >
            <div
              class="v-center"
              style="
                height: 225px;
                width: 1250px;
                display: flex;
                margin: 0 auto;
                justify-content: space-between;
                background: url(/pic/OncoSexome-title.png);
                background-size: contain;
                position: relative;
              "
            >
              <div
                style="
                  position: absolute;
                  cursor: pointer;
                  top: 82px;
                  height: 34px;
                  width: 218px;
                  left: 97px;
                  text-align: right;
                "
                @click="playAudio1"
                title="Click to Read OncoSexome"
              >
                <img
                  src="/pic/play.svg"
                  style="display: initial; width: 25px; margin-top: 4px"
                />
                <audio
                  ref="audio"
                  src="/pic/OncoSexome.mp3"
                  preload="auto"
                ></audio>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 60px" class="content-width">
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100%;
            "
          >
            <el-menu
              class="el-menu-demo"
              mode="horizontal"
              background-color="#455da500"
              text-color="#fff"
              style="margin: auto 0"
            >
              <el-menu-item>
                <router-link to="/" class="no-line"
                  ><i class="fa fa-home" aria-hidden="true"></i>
                  Home</router-link
                >
              </el-menu-item>

              <el-submenu index="4">
                <template slot="title">
                  <i class="fa fa-search-plus" aria-hidden="true"></i>
                  Search
                </template>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-drug-page"
                    class="no-line"
                    style="color: black"
                  >
                    Sex-based Differences in the Antineoplastic Drugs and
                    Response (SDR)
                  </router-link>
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-biomarker-page"
                    class="no-line"
                    style="color: black"
                  >
                    Sex-based Differences in the Oncology Relevant Biomarker
                    (SBM)
                  </router-link>
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-factor-page"
                    class="no-line"
                    style="color: black"
                  >
                    Sex-based Differences in the Risk Factors for Cancer (SRF)
                  </router-link>
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-microbiota-page"
                    class="no-line"
                    style="color: black"
                  >
                    Sex-based Differences in the Microbial Landscape in Cancer
                    Host (SML)
                  </router-link>
                </el-menu-item>
                <el-menu-item class="submenu-item">
                  <router-link
                    to="/search-disease-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Cancer Type with Sex-based Differences
                  </router-link>
                </el-menu-item>
              </el-submenu>
              <!-- <el-menu-item>
                <router-link to="/to-be-available" class="no-line"
                  ><i
                    class="fa fa-list"
                    aria-hidden="true"
                    style="margin-bottom: 4px"
                  ></i
                  >Browse</router-link
                >
              </el-menu-item> -->
              <el-menu-item>
                <router-link to="/download" class="no-line"
                  ><i class="fa fa-cloud-download" aria-hidden="true"></i
                  >Download</router-link
                >
              </el-menu-item>
              <el-menu-item>
                <router-link to="/manual" class="no-line"
                  ><i class="fa fa-file-text-o" aria-hidden="true"></i
                  >Manual</router-link
                >
              </el-menu-item>

              <el-menu-item>
                <router-link to="/contact" class="no-line"
                  ><i class="fa fa-address-book-o" aria-hidden="true"></i
                  >Contact</router-link
                >
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <!-- <div
          style="display: flex; height: 60px; justify-content: space-between"
          class="content-width"
        >
          <div style="margin: auto 0; padding: 5px 0 0 0">
            <a class="" href="/" style="">
              <img
                :src="`${localUrl}/files/home-picture/TheMarker.svg`"
                height="52px"
                style="display: initial"
              />
            </a>
          </div>
          <div style="display: flex; flex-direction: row-reverse">
            <el-menu
              class="el-menu-demo"
              mode="horizontal"
              background-color="#455da500"
              text-color="#fff"
              style="margin: auto 0"
            >
              <el-menu-item>
                <router-link to="/" class="no-line"
                  ><i class="fa fa-home" aria-hidden="true"></i>
                  Home</router-link
                >
              </el-menu-item>

              <el-submenu index="4">
                <template slot="title">
                  <i class="fa fa-search-plus" aria-hidden="true"></i>
                  Search</template
                >
                <el-menu-item class="submenu-item" index="4-1">
                  <router-link
                    to="/search-drug-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Drug</router-link
                  >
                </el-menu-item>
                <el-menu-item class="submenu-item" index="4-2">
                  <router-link
                    to="/search-disease-page"
                    class="no-line"
                    style="color: black"
                  >
                    Search for Disease</router-link
                  >
                </el-menu-item>
              </el-submenu>
              <el-menu-item>
                <router-link to="/statistics" class="no-line"
                  ><i class="fa fa-pie-chart" aria-hidden="true"></i>Statistics
                </router-link>
              </el-menu-item>
              <el-menu-item>
                <router-link to="/contact" class="no-line"
                  ><i class="fa fa-address-book-o" aria-hidden="true"></i
                  >Contact</router-link
                >
              </el-menu-item>
            </el-menu>
          </div>
        </div> -->
      </el-header>

      <el-main>
        <router-view />
      </el-main>

      <br />
      <el-footer id="page-foot" style="height: auto" class="home-gradient">
        <div id="page-foot-row" style="height: 266px">
          <el-row class="content-width" style="display: flex; height: 100%">
            <el-col :span="8">
              <h3 class="foot-title"><b>Cite OncoSexome</b></h3>
              <div class="foot-content">
                <p>
                  X. Y. Shen, Y. T. Zhang, J. M. Li, Y. Zhou, S. Butensky, Y. C. Zhang, Z. W. Cai, A. T. DeWan, S. A. Khan, H. Yan, C. H. Johnson*, F. Zhu*. OncoSexome: the landscape of sex-based differences in oncologic diseases.
                  <a href="https://doi.org/10.1093/nar/gkae1003" target="_blank"
                    ><b><i>Nucleic Acids Research</i></b></a
                  >. doi: 10.1093/nar/gkae1003 (2024).
                  <!-- <a
                    href="https://www.ncbi.nlm.nih.gov/pubmed/37850638"
                    target="_blank"
                    >PMID: 37850638</a
                  >. -->
                </p>
              </div>
            </el-col>
            <el-col :span="8">
              <h3 class="foot-title"><b>Visitor Map</b></h3>
              <div
                class="foot-content"
                style="text-align: center; padding-top: 18px"
              >
                <iframe
                  style="transform: scale(1.23)"
                  :src="iframe_url"
                  frameborder="0"
                ></iframe>
              </div>
            </el-col>
            <el-col :span="8">
              <h3 class="foot-title"><b>Correspondence</b></h3>
              <div class="foot-content">
                <span>
                  <b
                    >Prof. Feng Zhu&nbsp;
                    <a
                      href="https://www.idrblab.org/zhufeng.php"
                      target="_blank"
                    >
                      <i class="fa fa-external-link" aria-hidden="true"></i></a
                  ></b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <i>
                  College of Pharmaceutical Sciences, Zhejiang University,
                  China. </i
                ><br />
                <div style="margin-top: 15px">
                  <b
                    >Prof. Caroline H Johnson&nbsp;
                    <a
                      href="https://medicine.yale.edu/lab/cjohnson/"
                      target="_blank"
                    >
                      <i
                        class="fa fa-external-link"
                        aria-hidden="true"
                      ></i></a></b
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <i>
                    Yale School of Public Health, Yale University, United States
                  </i>
                </div>

                <div style="margin-top: 15px">
                  <b
                    >Prof. Hong Yan&nbsp;
                    <a href="https://yanhongxuejiao.github.io/" target="_blank">
                      <i
                        class="fa fa-external-link"
                        aria-hidden="true"
                      ></i></a></b
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  <i> SKLEBA, Hong Kong Baptist University, Hong Kong. </i>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>


<script>
import pathUrl from "./api/path";
export default {
  name: "App",
  data() {
    return {
      localUrl: pathUrl.localUrl,
      isCollapse: false,
      iframe_url: `${pathUrl.localUrl}/files/visitermap`,
      test: ["a", "b"],
      aaaa: {
        particles: {
          move: {
            enable: true,
            speed: { min: 1, max: 6 },
          },
          number: {
            value: 60,
            max: 120,
          },
          opacity: {
            value: 1,
          },
          rotate: {
            path: true,
          },
          shape: {
            options: {
              image: {
                // gif: true,
                // height: 200,
                src: "/pic/logo.png",
                // width: 200,
              },
            },
            type: "image",
          },
          size: {
            value: {
              min: 10,
              max: 64,
            },
          },
        },
      },
      aaaa1: {
        detectRetina: true,
        fpsLimit: 60,
        interactivity: {
          detectsOn: "canvas",
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onDiv: {
              elementId: "repulse-div",
              enable: false,
              mode: "repulse",
            },
            onHover: {
              enable: true,
              mode: "bubble",
              parallax: {
                enable: false,
                force: 60,
                smooth: 10,
              },
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 1,
              size: 40,
              speed: 3,
            },
            connect: {
              distance: 80,
              lineLinked: {
                opacity: 0.5,
              },
              radius: 60,
            },
            grab: {
              distance: 400,
              lineLinked: {
                opacity: 1,
              },
            },
            push: {
              quantity: 4,
            },
            remove: {
              quantity: 2,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          lineLinked: {
            blink: false,
            color: "#000",
            consent: false,
            distance: 150,
            enable: false,
            opacity: 0,
            width: 0,
          },
          rotate: {
            value: 0,
            random: true,
            direction: "clockwise",
            animation: {
              enable: true,
              speed: 5,
              sync: false,
            },
          },
          move: {
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
            bounce: false,
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            limit: 0,
            value: 80,
          },
          opacity: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 1,
              sync: false,
            },
            random: false,
            value: 0.8,
          },
          shape: {
            character: {
              fill: false,
              font: "Verdana",
              style: "",
              value: "*",
              weight: "400",
            },
            image: [
              {
                src: "https://particles.js.org/images/fruits/apple.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/avocado.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/banana.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/berries.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/cherry.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/grapes.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/lemon.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/orange.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/peach.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/pear.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/pepper.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/plum.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/star.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/strawberry.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/watermelon.png",
                width: 32,
                height: 32,
              },
              {
                src: "https://particles.js.org/images/fruits/watermelon_slice.png",
                width: 32,
                height: 32,
              },
            ],
            polygon: {
              nb_sides: 5,
            },
            stroke: {
              color: "#000000",
              width: 0,
            },
            type: "image",
          },
          size: {
            animation: {
              enable: false,
              minimumValue: 0.1,
              speed: 40,
              sync: false,
            },
            random: false,
            value: 16,
          },
        },
        polygon: {
          draw: {
            enable: false,
            lineColor: "#ffffff",
            lineWidth: 0.5,
          },
          move: {
            radius: 10,
          },
          scale: 1,
          type: "none",
          url: "",
        },
      },
    };
  },
  methods: {
    playAudio1() {
      this.$refs.audio.play();
    },
    async particlesLoaded(container) {
      console.log("Particles container loaded", container);
    },
  },
};
</script>


<style lang="less">
@purple: #455da500;

#app {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.content-width {
  width: 1250px;
  margin: 0px auto !important;
}
.el-menu-item:hover,
.el-submenu:hover {
  outline: 0;
  background-color: #bce9f04f !important;
  border-radius: 10px;
}

p {
  margin: 5px 0;
}

h3 {
  margin: 15px 0;
}

.el-menu--horizontal {
  // 临时
  // display: block !important;
  .el-submenu__title {
    border-radius: 10px;
    font-size: 24px;
    padding-right: 0;
  }
  .el-submenu__title:hover {
    background-color: #bce9f04f !important;
  }
  .el-submenu__icon-arrow {
    margin-top: 6px !important;
  }
  .el-menu-item,
  .el-submenu {
    // border-radius: 10px;
    // font-size: 20px;
    // font-weight: bold;
    // padding: 0px;

    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    padding: 0px;
    // margin: 0 52px 0 0;
  }
  .el-menu {
    .el-menu-item {
      height: 60px !important;
      padding: 10px !important;
    }
  }
}
// .el-menu--popup {

// }

.el-menu--popup {
  background: #fff !important;
  border-radius: 5px !important;
  padding: 5px 0 !important;
  // box-shadow: 0 6px 12px 2px rgb(171 168 168) !important;
  border: 1px dashed #85858552 !important;
  // border-style: dashed;
  box-shadow: 4px 5px 6px 0px #a1a1a1 !important;
}
.el-header,
.el-main,
.el-footer {
  padding: 0 !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

body {
  display: block;
  margin: 0;
  line-height: 1.42857;
  font-size: 20px;
}

.foot-title {
  color: #ffffff;
  border-bottom: 3px solid #fff;
  font-size: 24px;
  text-align: left;
  padding-top: 10px;
}

.foot-content {
  color: #ffffff;
  text-align: left;
  font-size: 16px;
  line-height: 1.6;
}

.el-row {
  margin: 0 3%;
  margin-bottom: 20px;
}

.el-col {
  padding: 0 20px;
}

.el-menu-item,
.el-submenu {
  i {
    color: #ffffff !important;
    margin-right: 10px;
    font-size: 24px !important;
    margin-bottom: 8px;
  }
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #409eff00 !important;
  color: #ffffff !important;
}
/* .el-menu-item i:hover{
  color: #ffd04b
} */

.no-line {
  text-decoration: none !important;
  padding: 17px;
}

nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
  }
  a.router-link-exact-active {
    color: #42b983;
  }
}
.el-main {
  overflow: hidden;
}
.el-button,
.fs20,
.el-tag,
.el-message-box__content,
.el-upload-list__item,
.el-notification__content,
.el-select-dropdown__item,
.el-input--suffix .el-input__inner,
.el-input {
  font-size: 20px !important;
}
.el-backtop {
  box-shadow: 0 0 6px 1px #03a9f4 !important;
}
.home-gradient {
  background: #3f4f60;
}

// .el-loading-mask {
//   border-radius: 10px;
// }

.submenu-item {
  background: #fff !important;
  color: #000 !important;
  border-radius: 0 !important;
  font-size: 20px !important;
}
.submenu-item:hover {
  background: #ff584c30 !important;
}
// .el-menu--horizontal .el-menu .el-menu-item
</style>

